<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="main-body">
        <Modal
          v-model="addDevModal"
          title="请选择设备"
          @on-ok="admok"
          @on-cancel="admcancel"
          :width="700"
          class="addDevModal"
        >
          <div class="adm">
            <div class="adm-search">
              <Form
                :model="admForm"
                :label-width="60"
                @keydown.native.enter.prevent="() => {}"
              >
                <FormItem label="搜索">
                  <Input
                    v-model="admForm.searchText"
                    placeholder="请输入设备机台号"
                    @on-enter="getFather"
                  ></Input>
                </FormItem>
              </Form>
            </div>
            <div class="adm-table">
              <Table
                :height="300"
                :highlight-row="true"
                no-data-text=" "
                :columns="admColumns"
                :data="admData"
                @on-row-click="getRowInfo"
              ></Table>
            </div>
          </div>
        </Modal>
        <Form
          class="formValidate"
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="110"
        >
          <div class="action-area">
            <div class="actBtn cancle" @click="cancle">
              <!-- <img v-if="editState == false" src="/static/images/aoya/edit.png" alt=""> -->
              <i v-if="editState == false" class="icon1 iconbianji"></i>
              <i v-if="editState == true" class="icon1 iconiconquxiao-06"></i>
              <!-- <img v-if="editState == true" src="/static/images/aoya/cancel.png" alt=""> -->
              <span v-if="editState == false">编辑</span>
              <span v-if="editState == true">取消</span>
            </div>
            <div
              v-if="editState == false"
              class="actBtn save"
              @click="delModal = true"
            >
              <i class="icon1 iconlajitong"></i>
              <span>删除</span>
            </div>
            <div
              v-if="editState == true"
              class="actBtn save"
              @click="save('formValidate')"
            >
              <i class="icon1 iconbaocun"></i>
              <span>保存</span>
            </div>
          </div>
          <div class="basicInfo">
            <div class="infoTitle_">设备保养单</div>
            <div class="infoDetail">
              <FormItem>
                <Row>
                  <Col span="10">
                    <FormItem prop="byCode" label="设备保养单号">
                      <div v-if="editState == false" class="textfont">
                        {{ formValidate.byCode }}
                      </div>
                      <Input
                        v-if="editState == true"
                        v-model="formValidate.byCode"
                        placeholder="请输入"
                        style="width:70%;border:none;"
                        disabled
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="creatname" label="制单人">
                      <div v-if="editState == false" class="textfont">
                        {{ formValidate.creatname }}
                      </div>
                      <Input
                        v-if="editState == true"
                        v-model="formValidate.creatname"
                        placeholder="请输入"
                        style="width:70%;border:none;"
                        disabled
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="10">
                    <FormItem prop="byDateplan" label="计划保养日期">
                      <div v-if="editState == false" class="textfont">
                        {{
                          this.$globalFun.format_time(formValidate.byDateplan)
                        }}
                      </div>
                      <DatePicker
                        v-if="editState == true"
                        type="date"
                        placeholder="请选择日期"
                        v-model="formValidate.byDateplan"
                        style="width:70%;border:none;"
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="byCreattimme" label="制单日期">
                      <div v-if="editState == false" class="textfont">
                        {{
                          this.$globalFun.format_time(formValidate.byCreattimme)
                        }}
                      </div>
                      <DatePicker
                        v-if="editState == true"
                        type="date"
                        placeholder="请选择日期"
                        v-model="formValidate.byCreattimme"
                        style="width:70%;border:none;"
                        :disabled="true"
                      ></DatePicker>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="10">
                    <FormItem prop="deviceNum" label="机台号">
                      <Row v-if="editState == false">
                        <Col :span="8">
                          <div v-if="editState == false" class="textfont">
                            {{ formValidate.items[0].deviceNum }}
                          </div>
                        </Col>
                        <Col :span="8">
                          <div
                            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 500;color: #333333;"
                          >
                            设备编号:{{ formValidate.items[0].serialNumber }}
                          </div>
                        </Col>
                      </Row>
                      <!-- <div v-if="editState == false" class="textfont">{{formValidate.items[0].deviceNum}}</div> -->
                      <Row v-if="editState == true">
                        <Col :span="8">
                          <Input
                            v-if="editState == true"
                            type="text"
                            v-model="formValidate.items[0].deviceNum"
                            placeholder="请输入机台号"
                            @on-focus="addDevModal = true"
                          ></Input>
                          <Icon
                            style="position:absolute;right:20px;top:22px;color:rgba(140,171,190,1);"
                            type="ios-arrow-down"
                            @click="addDevModal = true"
                          />
                        </Col>
                        <Col :span="8">
                          <div
                            v-show="formValidate.items[0].deviceNum != ''"
                            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 500;color: #333333;"
                          >
                            设备编号:{{ formValidate.items[0].serialNumber }}
                          </div>
                        </Col>
                      </Row>
                      <!-- <Input v-if="editState == true" type="text" v-model="formValidate.items[0].deviceNum" placeholder="请输入机台号" style="width:70%;border:none;" @on-focus="addDevModal = true"></Input> -->
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="byContent" label="保养内容">
                      <!-- <div v-if="editState == false" class="textfont">{{formValidate.byContent}}</div> -->
                      <div v-if="editState == false" class="textfont">
                        <Row style="border:none;">
                          <Col span="15">
                            {{ formValidate.byContent }}
                          </Col>
                        </Row>
                      </div>
                      <Input
                        v-if="editState == true"
                        type="text"
                        v-model="formValidate.byContent"
                        placeholder="请输入保养内容"
                        style="width:70%;border:none;"
                        maxlength="100"
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </div>
          </div>
        </Form>
        <div class="otherInfo">
          <div class="infoTitle">
            <div
              style="width:110px;border-bottom:1px solid #DFE3E8;height:56px;line-height:56px;"
            >
              实施保养人
            </div>
          </div>
          <div class="infoDetail">
            <Form class="otherForm" :label-width="110">
              <FormItem>
                <Row style="border-top:1px solid #DFE3E8;">
                  <Col span="10">
                    <FormItem prop="byDealerId" label="员工编号">
                      <div class="textfont">{{ formValidate.byDealerId }}</div>
                      <!-- <div v-if="editState == false" class="textfont">{{formValidate.byDealerId}}</div>
                                    <Input v-if="editState == true" v-model="formValidate.byDealerId" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="dealNum" label="保养人">
                      <div class="textfont">{{ formValidate.dealname }}</div>
                      <!-- <div v-if="editState == false" class="textfont">{{formValidate.dealNum}}</div>
                                    <Input v-if="editState == true" v-model="formValidate.dealNum" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="10">
                    <FormItem prop="dealDept" label="部门">
                      <div class="textfont">{{ formValidate.dealDept }}</div>
                      <!-- <div v-if="editState == false" class="textfont">{{formValidate.dealDept}}</div>
                                    <Input v-if="editState == true" v-model="formValidate.dealDept" placeholder="请输入" style="width:200px;"></Input>  -->
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="byDealtime" label="实际保养日期">
                      <div class="textfont">{{ formValidate.byDealtime }}</div>
                      <!-- <div v-if="editState == false" class="textfont">{{formValidate.byDealtime}}</div>
                                    <DatePicker v-if="editState == true" type="date" placeholder="请选择日期" v-model="formValidate.byDealtime" style="width:70%;border:none;"></DatePicker> -->
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Form>
          </div>
          <div class="infoTitle" style="border-bottom: 1px solid #DFE3E8;">
            <div
              style="width:110px;border-bottom:1px solid #DFE3E8;height:56px;line-height:56px;"
            >
              工作流日志
            </div>
          </div>
          <Table :columns="workhead" no-data-text=" " :data="workdata"></Table>
        </div>
      </div>
    </Content>
    <Modal v-model="delModal" title="删除" width="440">
      <div style="margin-bottom:17px">
        <i class="icon1 iconicon-gantanhao modalGantanhao"></i>是否确定删除？
      </div>
      <div class="modalDelMessage">
        删除操作不可撤销，删除的内容将不可恢复。
      </div>
      <div slot="footer">
        <Button @click="delModal = false" class="white-btn">取消</Button>
        <Button type="primary" @click="realDelete">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      delModal: false,
      addDevModal: false, // 显示选择设备编号弹窗
      admData: [],
      admForm: {
        searchText: "" // 搜索关键词
      },
      tempDevId: "", //
      tempDevNum: "",
      tempSerialNumber: "",
      admColumns: [
        {
          title: "机台号",
          key: "deviceNum",
          width: "150"
        },
        {
          title: "设备编号",
          key: "serialNumber",
          width: "150"
        },
        {
          title: "设备类型",
          key: "deviceType",
          width: "150"
        },
        {
          title: "所属车间",
          key: "workshop",
          width: "150"
        }
      ],

      parentGrouplist: [], // 设备父组树状表
      parentGroupId: "",
      defaultProps: {
        children: "list",
        label: "deviceNum"
      },
      editState: false,
      formValidate: {
        byCode: "", // "BY20190917002"
        byContent: "", // "测试用例勿删"
        byCreaterId: "", // 3
        byCreattimme: "", // "2019-09-17"
        byDateplan: "", // "2019-09-19"
        byDealerId: "", // 9
        byDealtime: "", // "2019-09-18"
        byId: "", // 2
        byType: "", // "1"
        creatname: "", // "设备员工1"
        dealDept: "", // "保养一部"
        dealNum: "", // "admin8"
        dealname: "", // "保养员工1"
        deviceId: "", // 2
        deviceNum: "", // "1112"

        devId: "",
        devType: "",
        workshop: "",
        date: "",
        items: [
          {
            index: 1,
            status: false,
            deviceId: "",
            deviceNum: "",
            byContent: "",
            name: "", // 搜索框内容
            parentGrouplist: [], // 当前搜索下的tree
            serialNumber: ""
          }
        ]
      },
      ruleValidate: {
        // devId: [
        //     { required: false, type: 'string', message: '请输入设备编号', trigger: 'blur' }
        // ],
        // devType: [
        //     { required: false, type: 'string', message: '请输入设备类型', trigger: 'blur' }
        // ]
      },
      workhead: [
        {
          title: "操作人",
          key: "dealName"
        },
        {
          title: "操作动作",
          key: "bylogType",
          render: (h, params) => {
            const arr = [];
            if (params.row.bylogType === 1) {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      padding: "0"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                        //   this.$router.push({
                        //   path: "/deviceManage/addDevmaintenance",
                        //   query: { byType: params.row.byType }
                        // });
                      }
                    }
                  },
                  "新建 "
                )
              );
            } else {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small",
                      disabled: true
                    },
                    style: {
                      marginRight: "5px"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                        //   this.$router.push({
                        //   path: "/deviceManage/devmaintenanceDetail",
                        //   query: { byType: params.row.byType }
                        // });
                      }
                    }
                  },
                  "更新"
                )
              );
            }

            return h("div", arr);
          }
        },
        {
          title: "操作时间",
          key: "bydate"
        },
        {
          title: "备注",
          key: "byremark"
        }
      ],
      workdata: [
        // {
        //     user: 'John Brown',
        //     action: "操作",
        //     mark: 'New York No. 1 Lake Park',
        //     date: '2016-10-03'
        // },
        // {
        //     user: 'John Brown',
        //     action: "操作",
        //     mark: 'New York No. 1 Lake Park',
        //     date: '2016-10-03'
        // },
        // {
        //     user: 'John Brown',
        //     action: "操作",
        //     mark: 'New York No. 1 Lake Park',
        //     date: '2016-10-03'
        // },
        // {
        //     user: 'John Brown',
        //     action: "操作",
        //     mark: 'New York No. 1 Lake Park',
        //     date: '2016-10-03'
        // },
      ]
    };
  },
  methods: {
    handleNodeClick(data) {
      if (data.deviceId !== undefined) {
        this.formValidate.items[0].deviceId = data.deviceId;
        this.formValidate.items[0].status = false;
        this.formValidate.items[0].deviceNum = data.deviceNum;
      }
    },
    getFather() {
      this.axios({
        url: "/iext/back/device/DeviceController/partDeviceNum",
        method: "get",
        params: {
          deviceNum: this.admForm.searchText
        }
      })
        .then((res) => {
          // this.parentGrouplist = res.data.body.numlist;
          this.admData = res.data.body.numlist;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancle() {
      this.getInfo();
      this.editState = !this.editState;
      this.formValidate.items[0].status = false;
    },
    formatDate(dta) {
      var d = new Date(dta);
      var youWant =
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      return youWant;
    },
    getInfo() {
      const that = this;
      this.axios({
        url: "/iext/back/device/DeviceController/maintainInfo",
        method: "get",
        params: {
          byId: this.$route.query.byId
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.formValidate.byCode = res.data.body.maintainInfo.byCode; // 保养单号
            that.formValidate.deviceNum = res.data.body.maintainInfo.deviceNum; // 设备编码
            that.formValidate.byContent = res.data.body.maintainInfo.byContent; // 保养内容
            that.formValidate.byCreaterId =
              res.data.body.maintainInfo.byCreaterId1; // 制单人id
            that.formValidate.byDateplan =
              res.data.body.maintainInfo.byDateplan; // 计划保养日期
            that.formValidate.creatname = res.data.body.maintainInfo.creatname; // 制单人姓名
            that.formValidate.byCreattimme =
              res.data.body.maintainInfo.byCreattimme; // 制单日期
            that.formValidate.byId = res.data.body.maintainInfo.byId; // 保养单id
            that.formValidate.byType = res.data.body.maintainInfo.byType; // 保养单状态 1待保养 2已保养
            that.formValidate.deviceId = res.data.body.maintainInfo.deviceId; // 设备id
            that.formValidate.byDealerId =
              res.data.body.maintainInfo.byDealerId; //  保养人id
            that.formValidate.dealname = res.data.body.maintainInfo.dealname; // 保养人姓名
            that.formValidate.byDealtime =
              res.data.body.maintainInfo.byDealtime; // 实际保养时间
            that.formValidate.dealDept = res.data.body.maintainInfo.dealDept; // 保养人部门

            // deviceId: '',
            // deviceNum: '',
            // byContent: '',
            that.formValidate.items[0].deviceId =
              res.data.body.maintainInfo.deviceId;
            that.formValidate.items[0].deviceNum =
              res.data.body.maintainInfo.deviceNum;
            that.formValidate.items[0].serialNumber =
              res.data.body.maintainInfo.serialNumber;

            that.workdata = res.data.body.byLoglist;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // baocun
    save(typeNum) {
      if (
        this.formValidate.items[0].deviceNum === "" ||
        this.formValidate.byContent === "" ||
        this.formValidate.byDateplan === ""
      ) {
        this.$message.error("请将基础信息补充完整后提交");
      } else {
        // axios
        //   .post(
        //     this.$api.baseUrl +
        //       "/iext/back/device/DeviceController/updateMaintain?byId=" +
        //       parseInt(this.$route.query.byId) +
        //       "&deviceId=" +
        //       this.formValidate.items[0].deviceId +
        //       "&byPlantime=" +
        //       this.formateDate(this.formValidate.byDateplan) +
        //       "&byContent=" +
        //       this.formValidate.byContent,
        //     {
        //       headers: {
        //         "Content-Type": "application/json"
        //       }
        //     }
        //   )
        //   .then((res) => {
        //     if (res.data.success === 1) {
        //       this.$Message.success("修改成功！");
        //       this.editState = !this.editState;
        //       this.formValidate.items[0].status = false;
        //     } else {
        //       this.$Message.error(res.data.msg);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      }
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      const d = new Date(datetime);
      const formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    realDelete() {
      const byIds = [];
      byIds.push(this.$route.query.byId);
      const params = {
        list: byIds + ""
      };
      this.$api
        .cancleMainten(params)
        .then((res) => {
          if (res.success === 1) {
            this.$Message.success("删除成功！");
            this.delModal = false;
            this.$router.push({
              path: "/deviceManage/devmaintenance"
            });
          } else {
            this.$Message.warning(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleAdd() {
      this.index++;
      this.formValidate.items.push({
        value: "",
        index: this.index,
        status: 1
      });
    },
    handleRemove(index) {
      this.formValidate.items[index].status = 0;
    },
    // 弹窗点击一行获取数据
    getRowInfo(e) {
      this.tempDevId = e.deviceId;
      this.tempDevNum = e.deviceNum;
      this.tempSerialNumber = e.serialNumber;
    },
    admok() {
      this.formValidate.items[0].deviceNum = this.tempDevNum;
      this.formValidate.items[0].deviceId = this.tempDevId;
      this.formValidate.items[0].serialNumber = this.tempSerialNumber;
      this.addDevModal = false;
    },
    admcancel() {
      // this.getInfo();
    }
  },
  created() {},
  mounted() {
    this.getInfo();
    this.getFather();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .formValidate {
    .action-area {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .actBtn {
        margin: 30px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(99, 115, 129, 1);
        cursor: pointer;
      }
      .actBtn :hover {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2980b9;
        cursor: pointer;
      }
      .cancle {
      }
      .save {
      }
    }
    .basicInfo {
      .infoTitle_ {
        // font-size:16px;
        // font-family:PingFangSC;
        // font-weight:500;
        // color:rgba(51,51,51,1);
        height: 56px;
        width: 100%;
        line-height: 56px;
        text-align: center;
        background: rgba(228, 235, 241, 1);
        box-shadow: 0px -1px 0px 0px rgba(223, 227, 232, 1);
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: rgba(49, 69, 88, 1);
      }
      .infoDetail {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          // .ivu-row{
          //   border-bottom:1px solid #DFE3E8;
          // }
          .textfont {
            height: 56px;
            line-height: 56px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0;
          line-height: 56px;
          height: 56px;
          background: rgba(247, 250, 255, 1);
          text-align: center;
          border-bottom: 1px solid #dfe3e8;
        }
        .ivu-row {
          border-bottom: 1px solid #dfe3e8;
        }
      }
      // .infoDetail >>> .ivu-form-item >>>.ivu-form-item-content{
      //             margin-left:0px !important;
      //         }
    }
  }
  .otherInfo {
    .infoTitle {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      margin-top: 40px;
    }
    .infoDetail {
      .otherForm {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 56px;
            line-height: 56px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
        }
        .ivu-row {
          border-bottom: 1px solid #dfe3e8;
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0;
          line-height: 56px;
          height: 56px;
          background: rgba(247, 250, 255, 1);
          text-align: center;
          border-bottom: 1px solid #dfe3e8;
        }
      }
    }
  }
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  /deep/
  .ivu-form-item-label {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333;
  // border-bottom: 1px solid #DFE3E8;
  text-align: center;
}
.main-body .otherInfo .infoDetail .ivu-form-item /deep/ .ivu-form-item-label {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333;
  // border-bottom: 1px solid #DFE3E8;
  text-align: center;
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  /deep/
  .ivu-form-item-content {
  input {
    border: none;
  }
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  /deep/
  .ivu-form-item-content
  .textfont {
  height: 56px;
  line-height: 56px;
}

.addDevModal /deep/ {
  .ivu-modal-wrap {
    .ivu-modal {
      .ivu-modal-content {
        .ivu-modal-body {
          padding: 14px 16px;
          .adm {
            .adm-table {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
